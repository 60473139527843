import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  PreSignedUploadCreateGQL,
  PreSignedUploadFragment,
} from '@graphql/mutation/create/generated/presigned-upload-create.apollo';
import { PreSignedUploads } from '@graphql/types';
import { marker as _ } from '@jsverse/transloco-keys-manager/marker';
import * as Sentry from '@sentry/angular-ivy';
import { AbstractFileUploader, InputFile, ToastService } from '@ui';
import { Observable, catchError, filter, forkJoin, map, switchMap } from 'rxjs';

interface FileUpload {
  presignedUpload: PreSignedUploadFragment;
  file: File;
}

@Injectable({
  providedIn: 'root',
})
export class FileUploadService extends AbstractFileUploader<FileUpload> {
  private readonly preSignedUploadCreate = inject(PreSignedUploadCreateGQL);
  private readonly http = inject(HttpClient);
  private readonly toast = inject(ToastService);

  public upload(files: File[]): Observable<FileUpload[]> {
    const fileInputs = files.map((file) => ({ originalFileName: file.name, fileSize: file.size }));

    return this.preSignedUploadCreate
      .mutate({
        input: fileInputs,
      })
      .pipe(
        map((res) => res.data?.result),
        filter((res) => res?.__typename === 'PreSignedUploads'),
        map((presignedUpload) => {
          return (presignedUpload as PreSignedUploads).preSignedUploads.map((presignedUpload, i) => ({
            presignedUpload,
            file: files[i],
          }));
        }),
        switchMap((fileUpload) => {
          return forkJoin(fileUpload.map((item) => this.signPresignedRequest(item))).pipe(map(() => fileUpload));
        }),
      );
  }

  private signPresignedRequest(item: FileUpload): Observable<unknown> {
    return this.http
      .put(item.presignedUpload.url, item.file, {
        headers: {
          'Content-Type': item.file.type,
          'Content-Disposition': `inline; filename*=utf-8''${encodeURIComponent(item.file.name)}`,
        },
        reportProgress: true,
      })
      .pipe(
        catchError((err) => {
          Sentry.captureException(err);
          throw err;
        }),
      );
  }

  public handleSuccess(files: FileUpload[], idList: Map<string, InputFile>): void {
    for (const item of files) {
      idList.set(item.presignedUpload.id, { id: item.presignedUpload.id, fileName: item.file.name });
    }
  }

  public handleUnexpected(): void {
    this.toast.show({ text: _('uni.unexpected-error'), type: 'danger' });
  }

  public handleEmpty(): void {
    this.toast.show({ text: _('uni.file-is-empty'), type: 'danger' });
  }

  public handleTooLarge(corruptedFiles: string[]): void {
    console.warn(corruptedFiles);
    this.toast.show({ text: _('uni.file-too-large'), type: 'danger' });
  }

  public handleInvalidFormat(corruptedFiles: string[]): void {
    console.warn(corruptedFiles);
    this.toast.show({ text: _('uni.file-invalid-format'), type: 'danger' });
  }
}
